.sans,
.small,
.h1,
.h3color,
.big,
.tiny,
.tinyprice,
.highlight,
.eyebrow,
a:active,
a:visited,
a:link,
div.unified_widget p.seeMore,
div.unified_widget .carat,
div.left_nav .carat,
div.left_nav,
div.left_nav h2,
div.left_nav h3,
div.left_nav a:link,
div.left_nav a:visited,
.popover-tiny,
.horizontal-search,
.horizontal-websearch,
.topnav,
.topnav-active a:link,
.tabon a,
.tabon a:visited,
.taboff a,
.taboff a:visited div.leftnav_popover h2,
.signInMsg {
  font-family: verdana, arial, helvetica, sans-serif;
}
.listprice {
  font-family: arial, verdana, helvetica, sans-serif;
}
.price {
  font-family: arial, verdana, helvetica, sans-serif;
}

div.left_nav,
div.left_nav a:link,
div.left_nav a:visited {
  font-family: Arial, sans-serif;
}

.serif,
.sans,
.h1,
div.unified_widget .headline {
  font-size: 16px;
}
.big {
  font-size: 14px;
}
.small,
.h3color,
.highlight,
.horizontal-search {
  font-size: 12px;
}
body,
td,
th {
  font-family: verdana, arial, helvetica, sans-serif;
  font-size: 12px;
}
.signInMsg {
  font-size: 10px;
}
.tiny,
.tinyprice,
.popover-tiny,
.horizontal-websearch {
  font-size: 10px;
}

@page {
  /* size: 72mm 200mm; */
  margin: 0;
}

@media print {
  html,
  body {
    margin-top: 0px;
    color: #000000 !important;
    line-height: initial !important;
    font-size: small !important;
    font-family: verdana, arial, helvetica, sans-serif !important;
    -webkit-font-smoothing: initial !important;
    -webkit-text-size-adjust: initial !important;
  }

  body,
  td,
  th {
    font-family: verdana, arial, helvetica, sans-serif;
    font-size: 10px;
  }

  .serif,
  .sans,
  .h1,
  div.unified_widget .headline {
    font-size: medium;
  }
  .big {
    font-size: large;
  }
  .small,
  .h3color,
  .highlight,
  .horizontal-search {
    font-size: small;
  }
  .signInMsg {
    font-size: xx-small;
  }
  .tiny,
  .tinyprice,
  .popover-tiny,
  .horizontal-websearch {
    font-size: xx-small;
  }

  .app-settings-toggle,
  .ReactQueryDevtools {
    display: none !important;
  }
}
